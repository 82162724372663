import React, { Component } from "react";
import { BounceRight, FadeIn } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../store/actions/SwitchContent";
import { props_params } from "../../store/actions/PropsParams";
import { PlusCircle, ArrowLeft } from "react-feather";
import { toast } from "react-toastify";
import { launch_toaster } from "../../store/actions/IsToast";
import { toast_trigger } from "../../store/actions/ToastTrigger";
import { Home } from "../../global/Home";
import Axios from "axios";
import Spinner from "../../global/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EditDatabase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "",
      name: "",
      fetching: false,
      loading: false,
      startDate: new Date(),
    };
  }
  onChange2 = (startDate) => {
    this.setState({ startDate });
  };
  LoadData = () => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ fetching: true });
      //    Axios.post(`${Home}auth/settings/singleDatabase`, {
      //   token: token,
      //   id: this.props.data.params.length > 0 ? this.props.data.params[0] : 0,
      // })
      Axios.get(`${Home}auth/settings/database/${this.props.data.params.length > 0 ? this.props.data.params[0] : 0}`, {
        token: token,
        id: this.props.data.params.length > 0 ? this.props.data.params[0] : 0,
      })
        .then((res) => {
          console.log(res);
          this.setState({ fetching: false });
          if (res.data.success) {
            this.setState({ name: res.data.name });
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
            this.SwitchContent("", [0]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  ErrorHandler = (message) => {
    //console.clear();
    console.log(message);
    this.setState({ loading: false });
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let token = "";
    const { from, to } = this.state;
    const period_cover = `${from} - ${to}`;
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      let fd = new FormData();
      fd.append("name", this.state.name);
      fd.append("product", this.state.product);
      fd.append("token", token);
      fd.append("class_insured", this.state.class_insured);
      fd.append("policy_number", this.state.policy_number);
      fd.append("period_cover", period_cover);
      fd.append("duration", this.state.duration);
      fd.append("expiry_date", this.state.expiry_date);
      fd.append("dob", this.state.dob);
      fd.append("email", this.state.email);
      fd.append("telephone", this.state.telephone);
      fd.append("sum_insured", this.state.sum_insured);
      fd.append("premium_rate", this.state.premium_rate);
      fd.append("interest", this.state.interest);
      fd.append("markup", this.state.markup);
      fd.append("reg", this.state.reg);
      fd.append("commission", this.state.commission);
      fd.append("premium_type", this.state.premium_type);
      fd.append("make", this.state.make);
      fd.append("business_indicator", this.state.business_indicator);
      fd.append("id", this.props.data.params.length > 0 ? this.props.data.params[0] : 0);
      this.setState({ loading: true });
      Axios.post(`${Home}auth/settings/editDatabase`, fd)
        .then((res) => {
          console.log(res);
          this.setState({ loading: false });
          if (res.data.success) {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(true));
            this.SwitchContent(" ", [0]);
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
          }
        })
        .catch(
          (err) => this.ErrorHandler(err, "Error")
          //console.log(err.response.data.message),
        );
    }
  };

  componentDidMount() {
    this.LoadData();
  }
  render() {
    return (
      <>
        <BounceRight duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">Edit Database</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("Students_home")}
                        className="btn btn-danger btn-sm shadow"
                      >
                        <ArrowLeft color="white" size={35} /> Return
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
              {this.state.fetching ? (
                <Spinner size={70} />
              ) : (
                <form onSubmit={this.handleSubmit} className="mt-4">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      required
                      value={this.state.name}
                      onChange={this.handleChange}
                      className="form-control st-login-f"
                      name="name"
                      placeholder="Name"
                    />
                  </div>
                  {/* <div className="form-group">
                                        <label>Mid Year </label>
                                        <DatePicker 
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                            required
                                            calendarClassName="rasta-stripes "
                                            className="red-border form-control"
                                            selected={this.state.startDate} 
                                            onChange={date => this.onChange2(date)} />
                                    </div> */}

                  <div className="form-group">
                    {this.state.loading ? (
                      <Spinner size={40} />
                    ) : (
                      <button className="btn btn-primary2 shadow">
                        Save Changes
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </BounceRight>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(EditDatabase);
