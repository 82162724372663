import React, { Component } from 'react';
import { BounceRight,FadeIn,Zoom } from "animate-components";
import { connect } from 'react-redux';
import { switch_content } from '../../../../store/actions/SwitchContent';
import { props_params } from '../../../../store/actions/PropsParams';
import {PlusCircle, ArrowLeft } from 'react-feather';
import { toast } from 'react-toastify';
import { launch_toaster } from '../../../../store/actions/IsToast';
import { toast_trigger } from '../../../../store/actions/ToastTrigger';
import { Home } from '../../../../global/Home';
import Axios from 'axios';
import Spinner from '../../../../global/Spinner';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state ={
            startDate: new Date(),
            switch:"",
            name:"",
            loading:false,
            data:[],
            data2:[],
            details:[],
            lg:[],
            lgs:"",
            department:"",
            level:"",
            step:1,
            first_name:"",
            middle_name:"",
            surname:"",
            email:"",
            number:"",
            gender:"",
            religion:"",
            m_status:"",
            blood_group:"",
            r_address:"",
            local:"",
            state:"",
            country:"",
            languages:"",
            hobbies:"",
            height:"",
            weigth:"",
            nysc:"yes",
            nysc_certificate:"",
            nysc_year:"",
            dob:"",
            postal_address:"",
            user:"",
            dep:[],
            dep2:""
        }
    }

    LoadDatae=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            // this.setState({loading:true});
            Axios.post(`${Home}auth/settings/listDepartment`,{
               token: token
            })
           .then(res => {
            console.log(res);
           this.setState({dep:res.data});
           })
        .catch(err =>console.log(err));
        }
    }

    onChange2 = (startDate) =>{
        this.setState({ startDate });
      }
    LoadData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            Axios.post(`${Home}hr/department/datatable`,{
               token: token
            })
           .then(res => {
            //console.log(res);
           this.setState({data:res.data.data});
           })
        .catch(err =>console.log(err));
        }
    }
    
    ErrorHandler=(message)=>{
        //console.clear();
        console.log(message)
        this.setState({loading:false})
        toast.error('Error',{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false
            });
    }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
          if (event.target.name === 'state') {
            if (this.state.details[event.target.value].lgas) {
                this.setState({lg:this.state.details[event.target.value].lgas})
            }
        }
        }
      }
    
    SwitchContent=(name,id)=>{
        this.props.dispatch(switch_content(name));
        this.props.dispatch(props_params(id));
    }
    handleSubmit=(event)=>{
        event.preventDefault();         
     }

     mainHandleSubmit=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({loading:true})
            Axios.post(`${Home}auth/users/createUser`,{
                token:token,
                first_name:this.state.first_name,
                middle_name:this.state.middle_name,
                surname:this.state.surname,
                email:this.state.email,
                phone:this.state.number,
                gender:this.state.gender,
                religion:this.state.religion,
                marital_status:this.state.m_status,
                blood_group:this.state.blood_group,
                dob:`${this.state.startDate.getFullYear()}-${this.state.startDate.getMonth()+1 < 10 ? `0${this.state.startDate.getMonth()+1}` : this.state.startDate.getMonth()+1}-${this.state.startDate.getDate() < 10 ? '0'+this.state.startDate.getDate() : this.state.startDate.getDate()}`,
                r_address:this.state.r_address,
                p_address:this.state.postal_address,
                lga:this.state.lgs,
                state:this.state.details[this.state.state].id,
                country:this.state.country,
                languages:this.state.languages,
                hobbies:this.state.hobbies,
                height:this.state.height,
                weight:this.state.weigth,
                nysc:this.state.nysc,
                nysc_year:this.state.nysc_year,
                dep2:this.state.dep2,
                nysc_number:this.state.nysc_certificate,
                em:this.state.user === '3'?'1':null,
                hr:this.state.user === '1'?'1':null,
                lm:this.state.user === '2'?'1':null,
              })
               .then(res => {
                 console.log(res);
                 this.setState({loading:false,});
                 if (res.data.success) {
                    this.props.dispatch(launch_toaster(res.data.message));
                   this.props.dispatch(toast_trigger(true)); 
                   this.SwitchContent('',[0]);
                }else{
                    this.props.dispatch(launch_toaster(res.data.message));
                  this.props.dispatch(toast_trigger(false)); 
                }
                 
               })
                .catch(err =>
                    this.ErrorHandler(err,'Error')
               //console.log(err.response.data.message),
              );
        }         
     }

     ChangeStep=(number)=>{
            this.setState({step:number})
     }
     LeftButton=()=>{
        if (this.state.step === 1) {
            return <li class="disabled" aria-disabled="true">
            <a href="#" role="menuitem">Previous</a>
        </li>;

        }else if (this.state.step === 2) {
            return <li onClick={()=>this.ChangeStep(1)}  aria-disabled="false">
            <a href="#" role="menuitem">Previous</a>
        </li>;
        }else if (this.state.step === 3) {
            return <li onClick={()=>this.ChangeStep(2)}  aria-disabled="false">
            <a href="#" role="menuitem">Previous</a>
        </li>;
        }
     }


     RightButton=()=>{
        if (this.state.step === 1) {
            return <li onClick={()=>this.ChangeStep(2)} aria-disabled="false">
            <a href="#" role="menuitem">Next</a>
        </li>;

        }else if (this.state.step === 2) {
            return <li onClick={()=>this.ChangeStep(3)}  aria-disabled="false">
            <a href="#" role="menuitem">Next</a>
        </li>;
        }else if (this.state.step === 3) {
            if (this.state.loading) {
                return <li   aria-disabled="false">
                <Spinner size={40} />
            </li>;
            }else{
                return <li onClick={()=>this.mainHandleSubmit()}  aria-disabled="false">
                 <a href="#" role="menuitem">Submit Now</a></li>;
            }
            
        
        }
     }

     LoadLocations=()=>{
        Axios.get(`${Home}locations`,
          )
         .then(res => {
           //console.log('Locations',res);
         this.setState({details:res.data})
         
        })
      }

     componentDidMount(){
         this.LoadDatae();
         this.LoadData();
         this.LoadLocations()
         Axios.get(`https://restcountries.eu/rest/v2/all`)
         .then(res => {
            // console.log(res)
             const data2 = res.data;
             this.setState({ data2 });
         })
         .catch(err =>console.log(err));
     }
    render() {
        return (
            <>
            <BounceRight duration="1s" timingFunction="ease-out">
                <div className="card border-0">
                <div className="card-body">
                <div className="row">
                    <div className="col-md-5">
                    <h6 className="lh-5 mg-b-0">Add new {this.props.data.quick_params} user</h6>
                    </div>
                    <div className="col-md-7">
                        <div className="pull-right">
                        <FadeIn duration="1s" timingFunction="ease-out">
                           <button onClick={()=>this.SwitchContent('user_home',[0])} className="btn btn-danger btn-sm shadow"><ArrowLeft color="white" size={35} /> Return</button>
                        </FadeIn>
                            
                        </div>
                        
                    </div>
                </div>
                <form onSubmit={this.handleSubmit} className="mt-4">
                    <div className="wizard clearfix" id="wizard2">
                        <div className="steps clearfix">
                            <ul className="steps">
                            <li className={`step-item ${this.state.step === 1?'active':''} ${this.state.step > 1 ? 'complete':''}`}>
                                <a href="" className="step-link">
                                <span className="step-number">1</span>
                                <span className="step-title">Personal Information</span>
                                </a>
                            </li>
                            <li className={`step-item ${this.state.step === 2?'active':''} ${this.state.step > 2 ? 'complete':''}`}>
                                <a href="" className="step-link">
                                <span className="step-number">2</span>
                                <span className="step-title">Account Information</span>
                                </a>
                            </li>
                            <li className={`step-item ${this.state.step === 3?'active':''} ${this.state.step > 3 ? 'complete':''}`}>
                                <a href="" className="step-link">
                                <span className="step-number">3</span>
                                <span className="step-title">Payment Information</span>
                                </a>
                            </li>
                            </ul>
                        </div>

                        <div className="content clearfix">
                            {this.state.step === 1 ?
                            
                        <BounceRight duration="1s" timingFunction="ease-out">
                          <h3 id="wizard2-h-0" tabindex="-1" class="title current">Personal Information</h3>
                          <section className="body current">
                              <p class="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>
                              <div className="row">
                              <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Type of user</label>
                                        <select 
                                        
                                        value={this.state.user}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="user"
                                        >
                                            <option value="">Select one</option>
                                            <option value="1">Admin</option>
                                            <option value="2">Line Managers</option>
                                            <option value="3">Employees</option>
                                        </select>
                                    </div>
                                  </div>

                                  {this.state.user !== "" || this.state.user !== '1' ?
                                  <div className="col-md-12">
                                  <div className="form-group">
                                      <label>User department</label>
                                      <select 
                                      
                                      value={this.state.dep2}
                                      onChange={this.handleChange}
                                      className="form-control st-login-f"
                                      name="dep2"
                                      >
                                          <option value="">Select department</option>
                                          {this.state.dep.length > 0 ?
                                          this.state.dep.map(er=>
                                          <option key={er.id} value={er.id}>{er.name}</option>
                                            )
                                        :''}
                                      </select>
                                  </div>
                                </div>
                                :''}
                                  <div className="col-md-4">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input 
                                        required
                                        value={this.state.first_name}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="first_name" placeholder="First Name" />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Middle Name</label>
                                        <input 
                                        required
                                        value={this.state.middle_name}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="middle_name" placeholder="Middle Name" />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                        <label>SureName</label>
                                        <input 
                                        required
                                        value={this.state.surname}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="surname" placeholder="SureName" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input 
                                        required
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="email" placeholder="Email Address" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <input 
                                        required
                                        value={this.state.number}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="number" placeholder="Phone Number" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Gender</label>
                                        <select 
                                        required
                                        value={this.state.gender}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="gender"
                                        >
                                            <option value="">Select one</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Religion</label>
                                        <select 
                                        required
                                        value={this.state.religion}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="religion"
                                        >
                                            <option value="">Select one</option>
                                            <option value="Christian">Christian</option>
                                            <option value="Muslim">Muslim</option>
                                            <option value="None">None</option>
                                        </select>
                                    </div>
                                  </div>
                              
                              </div>
                           </section>
                        </BounceRight>
                        :''}



                           {this.state.step === 2 ?
                            
                            <BounceRight duration="1s" timingFunction="ease-out">
                              <h3 id="wizard2-h-0" tabindex="-1" class="title current">Personal Information</h3>
                              <section className="body current">
                                  <p class="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>
                                  <div className="row">

                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Marital Status</label>
                                        <select 
                                        required
                                        value={this.state.m_status}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="m_status"
                                        >
                                            <option value="">Select one</option>
                                            <option value="Married">Married</option>
                                            <option value="Single">Single</option>
                                        </select>
                                    </div>
                                  </div>

                                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Religion</label>
                                        <select 
                                        required
                                        value={this.state.religion}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="religion"
                                        >
                                            <option value="">Select one</option>
                                            <option value="Christian">Christian</option>
                                            <option value="Muslim">Muslim</option>
                                            <option value="None">None</option>
                                        </select>
                                    </div>
                                  </div> */}


                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Blood Group</label>
                                        <input 
                                        required
                                        value={this.state.blood_group}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="blood_group" placeholder="Blood Group" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Date of birth</label>
                                        <DatePicker 
                                            required
                                            calendarClassName="rasta-stripes "
                                            className="red-border form-control"
                                            selected={this.state.startDate} 
                                            onChange={date => this.onChange2(date)} />
                                        {/* <input 
                                        required
                                        value={this.state.dob}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="dob" placeholder="Blood Group" /> */}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Residendial Address</label>
                                        <input 
                                        required
                                        type="address"
                                        value={this.state.r_address}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="r_address" placeholder="Residendial Address" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Postal Address</label>
                                        <input 
                                        required
                                        type="address"
                                        value={this.state.postal_address}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="postal_address" placeholder="Postal Address" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Select country</label>
                                    <select 
                                    required
                                    value={this.state.country}
                                    onChange={this.handleChange}
                                    className="form-control st-login-f"
                                    name="country"
                                    >
                                        <option value="">Select one</option>
                                        {this.state.data2.length > 0 ?
                                    this.state.data2.map(dep=>
                                    <option key={dep.key} value={dep.name}>{dep.name}</option>
                                        )  
                                    :''}
                                    </select>
                                </div>
                                  </div>


                                  {this.state.country == 'Nigeria' ? 
                                  <div className="col-md-6">
                                <div className="form-group">
                                <label>Select state</label>
                                <select 
                                required
                                value={this.state.state}
                                onChange={this.handleChange}
                                className="form-control st-login-f"
                                name="state"
                                >
                                    <option value="">Select one</option>
                                    {this.state.details.length > 0 ?
                                  this.state.details.map((dep,i)=>
                                  <option key={dep.id} value={i}>{dep.name}</option>
                                    )  
                                :''}
                                </select>
                            </div>
                            </div>
                            :''}

                                  {this.state.country == 'Nigeria' ? 
                                  <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Select Local Government</label>
                                        <select 
                                        required
                                        value={this.state.lgs}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="lgs"
                                        >
                                            <option value="">Select </option>
                                            {this.state.lg.length > 0 ?
                                        this.state.lg.map((dep,i)=>
                                        <option key={dep.id} value={dep.id}>{dep.name}</option>
                                            )  
                                        :
                                        <option value="">Select State first</option>
                                        }
                                        </select>
                                    </div>
                                    {/* <div className="form-group">
                                        <label>Local Government</label>
                                        <input 
                                        required
                                        type="text"
                                        value={this.state.local}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="local" placeholder="Local Government" />
                                    </div> */}
                                  </div>
                                :''}
                                  
                

                                  </div>
                               </section>
                            </BounceRight>
                            :''}




                          {this.state.step === 3 ?
                            
                            <BounceRight duration="1s" timingFunction="ease-out">
                              <h3 id="wizard2-h-0" tabindex="-1" class="title current">Personal Information</h3>
                              <section className="body current">
                                  <p class="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>
                                  <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Languages Known</label>
                                        <input 
                                        required
                                        type="text"
                                        value={this.state.languages}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="languages" placeholder="languages" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Hobbies</label>
                                        <input 
                                        required
                                        type="text"
                                        value={this.state.hobbies}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="hobbies" placeholder="Hobbies" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Height</label>
                                        <input 
                                        required
                                        type="text"
                                        value={this.state.height}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="height" placeholder="height" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Weight</label>
                                        <input 
                                        required
                                        type="text"
                                        value={this.state.weigth}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="weigth" placeholder="weigth" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>NYSC</label>
                                        <select 
                                        required
                                        type="text"
                                        value={this.state.nysc}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="nysc" placeholder="nysc">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                        <label>NYSC Year</label>
                                        <input 
                                        required
                                        type="text"
                                        value={this.state.nysc_year}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="nysc_year" placeholder="Year" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                        <label>NYSC Certificate</label>
                                        <input 
                                        required
                                        type="text"
                                        value={this.state.nysc_certificate}
                                        onChange={this.handleChange}
                                        className="form-control st-login-f"
                                        name="nysc_certificate" placeholder="Certificate" />
                                    </div>
                                  </div>
                                   
                                   
                                  </div>
                               </section>
                            </BounceRight>
                            :''}
                           
                           
                        </div>

                        <div className="actions clearfix">
                            <ul>
                            {this.LeftButton()}

                            {this.RightButton()}
                            </ul>
                        </div>
                    </div>
                
                    
                    
                    
                </form>
                
                </div>
                
            </div>
            </BounceRight>
            
            </>
        );
    }
}

const mapStoreToProps = (store) =>{
    return{
       data:store
    }
  }

export default connect(mapStoreToProps) (AddUser);