const getStatus = (expDate) => {
  const exp = new Date(expDate);
  const now = new Date();
  const rewDate = new Date(expDate);
  rewDate.setMonth(rewDate.getMonth() + 3);

  if (exp > now) {
    return ["Active", "success"];
  } else if (exp <= rewDate  && now<exp  ) {
    return ["Renewal Pending", "warning"];
  }

  return ["Inactive", "danger"];
};
// exp - 2022/jul/12
// paending - 2022/oct/12
// today 2021/may/14
export default getStatus;
