import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#35357d'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#35357d',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 23,
        fontSize: 12,
        fontStyle: 'bold',
        backgroundColor:'#35357d'
    },
    description: {
        width: '100%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 0,
        paddingLeft: 8,
        color:'#ffffff',
        justifyContent:'center',
        alignItems:'center',
        marginTop:3
        
    },
    total: {
        width: '15%',
        textAlign: 'left',
        paddingLeft: 8,
    },
  });


const KeysHeader = ({items}) => {
    
    return(    
        <View style={styles.row}>
            <Text style={styles.description}>Keys: </Text>
        </View>
    )
};
  
  export default KeysHeader