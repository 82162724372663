import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";
// import './assets/css/inonic.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Index from "./pages/Index";
import Login from "./pages/Login";
import LeftNav from "./layout/LeftNav";
import TopNav from "./layout/TopNav";
import RightNav from "./layout/RightNav";
import TestPage from "./pages/TestPage";
import Error404 from "./global/Error404";
import SettingsDepartment from "./pages/SettingsDepartment";
import SettingsJobPage from "./pages/SettingsJobPage";
import SettingsBranchPage from "./pages/SettingsBranchPage";
import UsersPage from "./pages/UsersPage";
import Logout from "./pages/Logout";
import SettingsEmploymentTypePage from "./pages/SettingsEmploymentTypePage";
import SettingLeaveTypes from "./pages/SettingLeaveTypes";
import SettingsSecurityQ from "./pages/SettingsSecurityQ";
import SettingsPerformance from "./pages/SettingsPerformance";
import SettingsHolidayPage from "./pages/SettingsHolidayPage";
import Dashboard from "./pages/Dashboard";
import SelfProfilePage from "./pages/SelfProfilePage";
import TrainingPage from "./pages/TrainingPage";
import LeavePage from "./pages/LeavePage";
import PerformancePage from "./pages/PerformancePage";
import PayrollPage from "./pages/PayrollPage";
import ExitPage from "./pages/ExitPage";
import QueryPage from "./pages/QueryPage";
import RequestPage from "./pages/RequestPage";
import SettingsRecuitPage from "./pages/SettingsRecuitPage";
import RecuitmentPage from "./pages/RecuitmentPage";
import SettingsSpecilizations from "./pages/SettingsSpecilizations";
import SettingsGrade from "./pages/SettingsGrade";
import SettingsEducationalGrade from "./pages/SettingsEducationalGrade";
import SettingsSkills from "./pages/SettingsSkills";
import SettingsExitQuestions from "./pages/SettingsExitQuestions";
import SettingsExitInterview from "./pages/SettingsExitInterview";
import SettingsItemsPage from "./pages/SettingsItemsPage";
import IssueItemsPage from "./pages/IssueItemsPage";
import UniversalInsurancePage from "./pages/UniversalInsurancePage";
import InsuranceLinkPage from "./pages/InsuranceLinkPage";
import SettingsActions from "./pages/SettingsActions";
import SettingsPayroll from "./pages/SettingsPayroll";
import SettingsObjective from "./pages/SettingsObjective";
import SettingsRetail from "./pages/SettingsRetail";
import SettingsPerfElement from "./pages/SettingsPerfElement";
import SettingsPerfRec from "./pages/SettingsPerfRec";
import ReportPage from "./pages/ReportPage";
import SettingsDivision from "./pages/SettingsDivision";
import DatabasePage from "./pages/DatabasePage";
import StoragePage from "./pages/StoragePage";
import SMSPage from "./pages/SMSPage";
import UserMm from "./pages/UserMm";
import PolicyPage from "./pages/PolicyPage";
import Toaster from "./global/Toaster";

class App extends Component {
  render() {
    // console.log(this.props);
    let login_layout = true;
    if (this.props.data.login_layout === "STEPHEN_WHOCODED") {
      login_layout = false;
    } else {
      login_layout = true;
    }
    // console.log(login_layout);
    return (
      <>
      <Toaster />
        <div className={login_layout ? "st-bg-gray3 st-scroll" : ""}>
          {login_layout ? <LeftNav /> : ""}

          <div
            className={
              login_layout
                ? `content ht-100v ${this.props.data.mmm ? "stttttg" : ""} pd-0`
                : ""
            }
          >
            {login_layout ? <TopNav /> : ""}
            <div className={login_layout ? "container pd-x-0 p-0" : ""}>
              {login_layout ? <div className="st-empty"></div> : ""}
              <div className={login_layout ? "content-body" : ""}>
                <Switch>
                  <Route path="/" exact component={Login} />
                  <Route path="/logout" exact component={Logout} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/dashboard" exact component={Dashboard} />
                  <Route path="/profile" exact component={SelfProfilePage} />
                  <Route
                    path="/settings/department"
                    exact
                    component={SettingsDepartment}
                  />
                  <Route
                    path="/settings/jobs"
                    exact
                    component={SettingsJobPage}
                  />
                  <Route
                    path="/settings/employments"
                    exact
                    component={SettingsEmploymentTypePage}
                  />
                  
                  <Route
                    path="/settings/leave"
                    exact
                    component={SettingLeaveTypes}
                  />
                  <Route
                    path="/settings/branch"
                    exact
                    component={SettingsBranchPage}
                  />
                  <Route
                    path="/settings/security-questions"
                    exact
                    component={SettingsSecurityQ}
                  />
                  <Route
                    path="/settings/performance-cycle"
                    exact
                    component={SettingsPerformance}
                  />
                  <Route
                    path="/settings/public-holidays"
                    exact
                    component={SettingsHolidayPage}
                  />
                  <Route
                    path="/settings/recuitement"
                    exact
                    component={SettingsRecuitPage}
                  />
                  <Route
                    path="/settings/specializations"
                    exact
                    component={SettingsSpecilizations}
                  />
                  <Route
                    path="/settings/grades"
                    exact
                    component={SettingsGrade}
                  />
                  <Route
                    path="/settings/educational-grade"
                    exact
                    component={SettingsEducationalGrade}
                  />
                  <Route
                    path="/settings/products"
                    exact
                    component={SettingsSkills}
                  />
                  <Route
                    path="/settings/exit_questions"
                    exact
                    component={SettingsExitQuestions}
                  />
                  <Route
                    path="/settings/exit_interview"
                    exact
                    component={SettingsExitInterview}
                  />
                  <Route
                    path="/settings/items"
                    exact
                    component={SettingsItemsPage}
                  />
                  <Route
                    path="/settings/discipline_actions"
                    exact
                    component={SettingsActions}
                  />
                  <Route
                    path="/settings/payroll_element"
                    exact
                    component={SettingsPayroll}
                  />
                  <Route
                    path="/settings/insurer-database"
                    exact
                    component={SettingsObjective}
                  />
                  <Route
                    path="/settings/performance_element"
                    exact
                    component={SettingsPerfElement}
                  />
                  <Route
                    path="/settings/performance_rec"
                    exact
                    component={SettingsPerfRec}
                  />
                  <Route
                    path="/settings/division"
                    exact
                    component={SettingsDivision}
                  />
                  <Route path="/users" exact component={UsersPage} />
                  <Route path="/training" exact component={TrainingPage} />
                  <Route path="/leave" exact component={LeavePage} />
                  <Route
                    path="/performance"
                    exact
                    component={PerformancePage}
                  />
                  <Route path="/payroll" exact component={PayrollPage} />
                  <Route path="/exit" exact component={ExitPage} />
                  <Route path="/query" exact component={QueryPage} />
                  <Route path="/request" exact component={RequestPage} />
                  <Route path="/recuitment" exact component={RecuitmentPage} />
                  <Route path="/issue_item" exact component={IssueItemsPage} />
                  <Route
                    path="/universal_insurance"
                    exact
                    component={UniversalInsurancePage}
                  />
                   {/* <Route
                    path="/policy"
                    exact
                    component={PolicyPage}
                  /> */}
                  <Route
                    path="/certificates"
                    exact
                    component={InsuranceLinkPage}
                  />
                  <Route path="/database" exact component={DatabasePage} />
                  <Route path="/storage" exact component={StoragePage} />
                  <Route path="/settings/test" exact component={TestPage} />
                  <Route path="/reports" exact component={ReportPage} />
                  <Route path="/sms" exact component={SMSPage} />
                  <Route path="/settings/users" exact component={UserMm} />
                  <Route
                    path="/settings/retail-partnership"
                    exact
                    component={SettingsRetail}
                  />
                  <Route
                    render={function () {
                      return (
                        <div className="st-e-h">
                          <Error404 />
                        </div>
                      );
                    }}
                  />
                </Switch>
              </div>
              {login_layout ? <div className="st-empty-larg"></div> : ""}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(App);
