import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import Invoice from './Invoice'
import invoice from './data/invoice'
import { BounceRight,FadeIn } from "animate-components";
// import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux';
import { switch_content } from '../../../store/actions/SwitchContent';
import { props_params } from '../../../store/actions/PropsParams';
import { User, ArrowLeft } from 'react-feather';
import Axios from 'axios';
import { Home } from '../../../global/Home';
import Spinner from '../../../global/Spinner';

class App extends Component {
  constructor(props) {
    super(props);
    this.state ={
      loading:false,
      details:""
    }
  }

  LoadData=()=>{
    let token = "";
    if (localStorage.getItem('userToken')) {
        token = JSON.parse(localStorage.getItem('userToken'));
        this.setState({loading:true});
        Axios.post(`${Home}${this.props.other === 'WHOCODED'?'auth/users/viewUser':'auth/profile/me'}`,{
           token: token,
           id:this.props.other === 'WHOCODED'?this.props.data.params.length > 0 ? this.props.data.params[0] : 0 :null
        })
       .then(res => {
        console.log('Profile',res);
       this.setState({loading:false,details:res.data});
       //this.props.dispatch(open_right('Close')); 
       })
    .catch(err =>console.log(err));
    }
}
componentDidMount(){
  this.LoadData();
}
  
  SwitchContent=(name,id)=>{
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
}
  render() {
   // console.log(this.props)
    return (
      <div>
        <BounceRight duration="1s" timingFunction="ease-out">
        <div className="card  border-0 mb-4">
                <div className="card-body">
                <div className="row">
                    <div className="col-md-5">
                    <h6 className="lh-5 mg-b-0">{this.state.loading ? 'Loading...':`${this.state.details.first_name} ${this.state.details.middle_name} ${this.state.details.surname}`} Profile PDF</h6>
                    </div>
                    <div className="col-md-7">
                        <div className="pull-right">
                        <FadeIn duration="1s" timingFunction="ease-out">
                           <button onClick={()=>this.SwitchContent('user_profile',[this.props.data.params[0]])} className="btn btn-danger btn-sm shadow"><ArrowLeft color="white" size={35} /> Return</button>
                        </FadeIn>
                            
                        </div>
                        
                    </div>
                </div>
                </div>
                </div>
        <div className="mt-4">
          {this.state.loading ? 
          <Spinner size={70} />
        :
         <Fragment>
            <PDFViewer width="900" height="900" className="app" >
                <Invoice invoice={this.state.details}/>
            </PDFViewer>
        </Fragment>
        }
       
      </div>
      </BounceRight>
      </div>
      
        
    );
  }
}

const mapStoreToProps = (store) =>{
  return{
     data:store
  }
}
export default connect(mapStoreToProps) (App);