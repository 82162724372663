import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import CommentRows from './CommentRows';
import KeysHeader from './KeysHeader';
import KeysRows from './KeysRows';

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1.5,
        borderColor: '#35357d',
    },
});

  const InvoiceItemsTable = ({invoice}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader invoice={invoice} />
        <InvoiceTableRow items={invoice} />
        <InvoiceTableFooter items={invoice.items} />
        <CommentRows comment={invoice}  />
        <KeysHeader items={invoice.items} />
        <KeysRows items={invoice.items} />
    </View>
  );
  
  export default InvoiceItemsTable