import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#35357d'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#35357d',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle:'normal',
    },
    description: {
        width: '30%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    qty2: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        color:'#ffffff'
    },
    rate: {
        width: '18%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    rate2: {
        width: '18%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        // color:'#ffffff',
        marginTop:3,
        textAlign:'center'
    },
    amount: {
        width: '19%',
        textAlign: 'center',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({items}) => {
    const rows = 
        <View>
            {typeof(items) ==='object' && items.markes.length > 0 ?
          items.markes.map(marks=>
            <View key={marks.id} style={styles.row}>
                <Text style={styles.description}>{marks.name}</Text>
                {marks.scores.length > 0 ?
                marks.scores.map((score,ii)=>
                <Text key={ii} style={styles.qty}>{score}</Text>
                )
            :
            marks.marksheetColumn.length > 0 ?
            marks.marksheetColumn.map((mm,i)=>
            <Text key={i} style={styles.qty2}>..</Text>
                )
            :''
                }
                <Text style={styles.qty}>{marks.total.length > 0 ? marks.total : ' '}</Text>
                <Text style={styles.rate}>{marks.highestMark.length > 0 ? marks.highestMark : ' '}</Text>
                <Text style={styles.rate2}>{marks.remark.length > 0 ? marks.remark : ' '}</Text>
                <Text style={styles.amount}>{marks.comment}</Text>
         </View>
            )  
        :''}
         
        </View>
        
    
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow