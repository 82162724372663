import React, { Component } from 'react';
import coming from '../../../assets/svg/whocoded_coming.svg'

class ComingSoon extends Component {
    render() {
        return (
            <div className="card border-0">
                <div className="card-body">
                    
                    <center>
                        <img src={this.props.img?this.props.img:coming} style={{width:'80%'}} className="img-fluid" title="Coming Soon" />
                        <div>
                        <h3 className="d-inline colo-b st-w600">Coming soon</h3>
                    </div>
                    </center>
                </div>
            </div>
        );
    }
}

export default ComingSoon;