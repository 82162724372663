import React, { Component } from "react";
import { BounceRight, FadeIn } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../store/actions/SwitchContent";
import { props_params } from "../../store/actions/PropsParams";
import {
  PlusCircle,
  ArrowLeft,
  Trash2,
  Edit,
  CheckCircle,
  Eye,
  Plus,
} from "react-feather";
import { toast } from "react-toastify";
import { launch_toaster } from "../../store/actions/IsToast";
import { toast_trigger } from "../../store/actions/ToastTrigger";
import { InsuranceUrl } from "../../global/Home";
import Axios from "axios";
import Spinner from "../../global/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchUser from "../../global/SearchUser";
// import { convertToBase64, setAuthBase64 } from "./SharedMethods";
import { Home } from "../../global/Home";
class ListPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "",
      name: "",
      loading: false,
      data: [],
      id: {},
      details: [],
      lg: [],
      show: "",
      note: "",
      number: "",
      data2: [],

      UserID: "",
      GSM_Number: "",
      Insured_Number: "",
      Chasis_Number: "",
      RegistrationNo: "",
      Vehicle_Make: "",
      Vehicle_Color: "",
      Vehicle_Model: "",
      Year_of_Make: "",
      Email: "",
      Policy_Type: "",
    };
  }

  onChange2 = (startDate) => {
    this.setState({ startDate });
  };
  onChange3 = (startDate2) => {
    this.setState({ startDate2 });
  };

  ErrorHandler = (message) => {
    //console.clear();
    console.log(message);
    this.setState({ loading: false });
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  Verify = async (reg) => {
    let token = JSON.parse(localStorage.getItem("userToken"));
    try {
      const data = await Axios.get(
        `${Home}auth/settings/policies/verify?registration_no=${reg}`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );

      toast.success(data.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
      this.SwitchContent("list_policy", [0]);
      this.OpenModal("", 0);
    } catch (error) {
      this.ErrorHandler("Verification Failed", "Error");
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };
  handleAddSubmit= async (event) => {
    event.preventDefault();
    let token = JSON.parse(localStorage.getItem("userToken"));
    this.setState({ loading: true });
    const {
      UserID,
      GSM_Number,
      Insured_Number,
      Chasis_Number,
      RegistrationNo,
      Vehicle_Make,
      Vehicle_Model,
      Vehicle_Color,
      Year_of_Make,
      Email,
      Policy_Type,
    } = this.state;

    const req = {
      user_id: UserID || this.state.id.user_id,
      registration_no: RegistrationNo || this.state.id.registration_no,
      chasis_no: Chasis_Number || this.state.id.chasis_no,
      vehicle_company: Vehicle_Make || this.state.id.vehicle_company,
      vehicle_color: Vehicle_Color || this.state.id.vehicle_color,
      vehicle_model: Vehicle_Model || this.state.id.vehicle_color,
      vehicle_year: Year_of_Make || this.state.id.vehicle_year,
      policy_type: Policy_Type || this.state.id.policy_type,
    };
    console.log(req);

    try {
      const data = await Axios.post(
        `${Home}auth/settings/policies/create`,
        req,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      toast.success(data.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
      this.SwitchContent("list_policy", [0]);
      this.OpenModal("", 0);
    } catch (err) {
      for (let key of Object.keys(err.response.data.errors)) {
        this.ErrorHandler(err.response.data.errors[key][0], "Error");
      }
    }

    this.setState({ loading: false });
  };


  handleSubmit = async (event) => {
    event.preventDefault();
    let token = JSON.parse(localStorage.getItem("userToken"));
    this.setState({ loading: true });
    const {
      UserID,
      GSM_Number,
      Insured_Number,
      Chasis_Number,
      RegistrationNo,
      Vehicle_Make,
      Vehicle_Model,
      Vehicle_Color,
      Year_of_Make,
      Email,
      Policy_Type,
    } = this.state;

    const req = {
      user_id: UserID || this.state.id.user_id,
      registration_no: RegistrationNo || this.state.id.registration_no,
      chasis_no: Chasis_Number || this.state.id.chasis_no,
      vehicle_company: Vehicle_Make || this.state.id.vehicle_company,
      vehicle_color: Vehicle_Color || this.state.id.vehicle_color,
      vehicle_model: Vehicle_Model || this.state.id.vehicle_color,
      vehicle_year: Year_of_Make || this.state.id.vehicle_year,
      policy_type: Policy_Type || this.state.id.policy_type,
    };
    console.log(req);

    try {
      const data = await Axios.put(
        `${Home}auth/settings/policies/${this.state.id.id}/update`,
        req,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      toast.success(data.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
      this.SwitchContent("list_policy", [0]);
      this.OpenModal("", 0);
    } catch (err) {
      for (let key of Object.keys(err.response.data.errors)) {
        this.ErrorHandler(err.response.data.errors[key][0], "Error");
      }
    }

    this.setState({ loading: false });
  };

  LoadData = () => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}auth/settings/policies`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data2: res.data.data });
        })
        .catch((err) => console.log(err));
    }
  };
  componentDidMount() {
    this.LoadData();
  }

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    id && this.setState({ id: id });
  };
  render() {
    // console.log(this.state)
    return (
      <>
        {this.state.name === "WHOCODED" ? (
          <SearchUser
            show={this.state.show}
            display={this.state.name === "WHOCODED" ? "block" : "none"}
            close={() => this.OpenModal("", 0)}
          />
        ) : (
          ""
        )}

        {this.state.name === "UPDATE_POLICY" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                <div className="modal-header">
                  <h5 className="modal-title">Update Policy</h5>
                  <button
                    onClick={() => this.OpenModal("", 0)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div>
                        <form onSubmit={this.handleSubmit} className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-5">
                              <label>User ID</label>
                              <input
                                // value={this.state.UserID}
                                defaultValue={this.state.id.user_id}
                                name="UserID"
                                disabled
                                required
                                placeholder="Enter User ID"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Chasis Number</label>
                              <input
                                // value={this.state.Chasis_Number}
                                defaultValue={this.state.id.chasis_no}
                                name="Chasis_Number"
                                required
                                placeholder="*******23re"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Registration No</label>
                              <input
                                // value={this.state.RegistrationNo}
                                defaultValue={this.state.id.registration_no}
                                name="RegistrationNo"
                                required
                                placeholder="*******y35"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Vehicle Make</label>
                              <input
                                // value={this.state.Vehicle_Make}
                                defaultValue={this.state.id.vehicle_company}
                                name="Vehicle_Make"
                                required
                                placeholder="Volvo, Benz"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Vehicle Color</label>
                              <input
                                // value={this.state.Vehicle_Color}
                                defaultValue={this.state.id.vehicle_color}
                                name="Vehicle_Color"
                                required
                                placeholder="White, Grey, Red, ....."
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Vehicle Model</label>
                              <input
                                // value={this.state.Vehicle_Model}
                                defaultValue={this.state.id.vehicle_model}
                                name="Vehicle_Model"
                                required
                                placeholder="Corolla, Nissan, ...."
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Year of Make</label>
                              <input
                                // value={this.state.Year_of_Make}
                                defaultValue={this.state.id.vehicle_year}
                                name="Year_of_Make"
                                required
                                placeholder="White, Grey, Red, ....."
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Policy Type</label>
                              <input
                                // value={this.state.Policy_Type}
                                defaultValue={this.state.id.policy_type}
                                name="Policy_Type"
                                required
                                placeholder="Enter Policy Type"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              {this.state.loading ? (
                                <Spinner size={40} />
                              ) : (
                                <button className="btn st-btn btn-primary shadow">
                                  Edit Now
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button onClick={()=>this.OpenModal('WHOCODED',0)} type="button" className="btn btn-success" data-dismiss="modal">{`${this.state.switch === 'WHOCODED'?'Close Edit':'Approve'}`}</button> */}

                  <button
                    onClick={() => this.OpenModal("", 0)}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}


{this.state.name === "ADD_POLICY" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                <div className="modal-header">
                  <h5 className="modal-title">Add Policy to Existing Database</h5>
                  <button
                    onClick={() => this.OpenModal("", 0)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div>
                        <form onSubmit={this.handleAddSubmit} className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-5">
                              <label>User ID</label>
                              <input
                                // value={this.state.UserID}
                                defaultValue={this.state.id.user_id}
                                name="UserID"
                                disabled
                                required
                                placeholder="Enter User ID"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Chasis Number</label>
                              <input
                                // value={this.state.Chasis_Number}
                                defaultValue={this.state.id.chasis_no}
                                name="Chasis_Number"
                                required
                                placeholder="*******23re"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Registration No</label>
                              <input
                                // value={this.state.RegistrationNo}
                                // defaultValue={this.state.id.registration_no}
                                name="RegistrationNo"
                                required
                                placeholder="*******y35"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Vehicle Make</label>
                              <input
                                // value={this.state.Vehicle_Make}
                                defaultValue={this.state.id.vehicle_company}
                                name="Vehicle_Make"
                                required
                                placeholder="Volvo, Benz"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Vehicle Color</label>
                              <input
                                // value={this.state.Vehicle_Color}
                                defaultValue={this.state.id.vehicle_color}
                                name="Vehicle_Color"
                                required
                                placeholder="White, Grey, Red, ....."
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Vehicle Model</label>
                              <input
                                // value={this.state.Vehicle_Model}
                                defaultValue={this.state.id.vehicle_model}
                                name="Vehicle_Model"
                                required
                                placeholder="Corolla, Nissan, ...."
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Year of Make</label>
                              <input
                                // value={this.state.Year_of_Make}
                                defaultValue={this.state.id.vehicle_year}
                                name="Year_of_Make"
                                required
                                placeholder="White, Grey, Red, ....."
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group ">
                              <label>Policy Type</label>
                              <input
                                // value={this.state.Policy_Type}
                                defaultValue={this.state.id.policy_type}
                                name="Policy_Type"
                                required
                                placeholder="Enter Policy Type"
                                className="form-control st-login-f"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              {this.state.loading ? (
                                <Spinner size={40} />
                              ) : (
                                <button className="btn st-btn btn-primary shadow">
                                  Add New Policy
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button onClick={()=>this.OpenModal('WHOCODED',0)} type="button" className="btn btn-success" data-dismiss="modal">{`${this.state.switch === 'WHOCODED'?'Close Edit':'Approve'}`}</button> */}

                  <button
                    onClick={() => this.OpenModal("", 0)}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}


        <BounceRight duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <h6 class="lh-5 mg-b-0">List Policy</h6>
                </div>
                <div className="col-md-9">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("list_policy", [0])}
                        className="btn btn-success shadow"
                      >
                        <PlusCircle color="white" size={35} /> List Policy
                      </button>
                    </FadeIn>
                  </div>

                  <div className="pull-right mx-3">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("create_policy", [0])}
                        className="btn btn-success shadow"
                      >
                        <PlusCircle color="white" size={35} /> Create Policy
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
              <section>
                <div className="p-3">
                  <div className="">
                    <table
                      id="stephen"
                      className="table mb-0 table-striped table-hover table-bordered table-responsive"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>User ID</th>
                          <th>RegistrationNo</th>
                          <th>Policy Number</th>
                          <th>Name</th>
                          <th>Policy Type</th>

                          <th>Chasis No</th>
                          <th>Vehicle Company</th>
                          <th>Email address</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading ? (
                          <Spinner />
                        ) : (
                          this.state.data2.map((dep, i) => (
                            <tr key={i}>
                              <td>{dep.user_id}</td>
                              <td>{dep.registration_no}</td>
                              <td>{dep.user.policy_number}</td>
                              <td>{dep.user.name}</td>
                              <td>{dep.policy_type}</td>
                              <td>{dep.chasis_no}</td>
                              <td>{dep.vehicle_company}</td>
                              <td>{dep.user.email}</td>
                              {/* 
                                <td>{dep.telephone}</td>
                                <td>{dep.sum_insured}</td>
                                <td>{dep.premium_rate}</td>
                                <td>{dep.interest}</td>
                                <td>{dep.make}</td>
                                <td>{dep.premium_type}</td>
                                <td>{dep.business_indicator}</td> */}

                              <td>
                                <div>
                                  <FadeIn
                                    duration="1s"
                                    timingFunction="ease-out"
                                  >


{(this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          3) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          1) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          5) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          4) ? (
                                        <button
                                          data-rh="Add Policy "
                                          onClick={() =>
                                            this.OpenModal("ADD_POLICY", dep)
                                          }
                                          className="btn btn-primary btn-sm m-1 shadow"
                                        >
                                          <Plus color="white" size={35} />{" "}
                                        </button>
                                      ) : (
                                        ""
                                      )}


                                    <div className=" d-flex">
                                      {(this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          3) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          1) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          5) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          4) ? (
                                        <button
                                          data-rh="Edit "
                                          onClick={() =>
                                            this.OpenModal("UPDATE_POLICY", dep)
                                          }
                                          className="btn btn-primary btn-sm m-1 shadow"
                                        >
                                          <Edit color="white" size={35} />{" "}
                                        </button>
                                      ) : (
                                        ""
                                      )}



                                      {(this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          3) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          1) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          5) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          4) ? (
                                        <button
                                          data-rh="Verify Policy "
                                          onClick={() =>
                                            this.Verify(dep.registration_no)
                                          }
                                          className="btn btn-primary btn-sm m-1 shadow"
                                        >
                                          <CheckCircle
                                            color="white"
                                            size={35}
                                          />{" "}
                                        </button>
                                      ) : (
                                        ""
                                      )}

                                      {/* {(this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          3) ||
                                      (this.props.data.userDetails &&
                                        this.props.data.userDetails.type ===
                                          1) ? (
                                        <button
                                          data-rh="Delete Policy"
                                          onClick={() => this.Delete(dep.id)}
                                          className="btn btn-danger btn-sm m-1 shadow"
                                        >
                                          <Trash2 color="white" size={35} />{" "}
                                        </button>
                                      ) : (
                                        ""
                                      )} */}
                                    </div>
                                  </FadeIn>
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </BounceRight>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListPolicy);
