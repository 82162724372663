import React, { Component, Fragment } from "react";
import { BounceRight, FadeIn, BounceUp, BounceLeft } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../store/actions/SwitchContent";
import { props_params } from "../../store/actions/PropsParams";
import getStatus from "../../utils/getStatus";
import * as XLSX from "xlsx";
import {
  PlusCircle,
  Edit,
  Trash2,
  List,
  ArrowLeft,
  Eye,
  Mail,
  Upload,
  Command,
  CheckCircle,
  File,
  ArrowDownCircle,
  MinusCircle,
} from "react-feather";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import Axios from "axios";
import { Home, API_BASE } from "../../global/Home";
import Spinner from "../../global/Spinner";
import { launch_toaster } from "../../store/actions/IsToast";
import { toast_trigger } from "../../store/actions/ToastTrigger";
import img from "../../assets/svg/whocoded_avatar.svg";
import img2 from "../../assets/svg/file.svg";
import { open_right } from "../../store/actions/OpenRight";
import App from "../uploads/App";
import filterKeysInArray from "../../utils/filterKeyInArray";
import Renewal from "./Renew";
import groupArrayByName from "../../utils/groupDatabase";

class ListDatabase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      current: {},
      exportData:"name expiry_date",
      name: "",
      show: "",
      fromDate: "",
      exportState: "Export",
      toDate: "",
      id: "",
      switch: "",
      filter: "all",
      modal: "",
      show: "",
      id: 0,
      filter2:"",
      data2: [],
      openPolicy: "",
      loading2: false,
      searchQuery: "",
    };
    this.fileInput = React.createRef();
  }

  handleSearchChange = (event) => {
    // Update the search query state when the user types in the search input field
    this.setState({ searchQuery: event.target.value });
  };

  Export = (type) => {
    function filterDataByDateRange(array, fromDate, toDate) {
      return fromDate && toDate
        ? array.filter((obj) => {
            const exp = new Date(obj.expiry_date);
            return exp >= new Date(fromDate) && exp <= new Date(toDate);
          })
        : array;
    }

    console.log("Exporting...");
    this.setState({ exportState: "Exporting...." });
    const filteredData = filterKeysInArray(this.state.data, type);
    const fil = filterDataByDateRange(
      filteredData,
      this.state.fromDate,
      this.state.toDate
    );

    console.log(fil)



    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fil);
    XLSX.utils.book_append_sheet(workbook, worksheet, "DataSheet");

    // Export the Excel file
    XLSX.writeFile(workbook, "exported_data.xlsx");
    this.setState({ exportState: "Export" });
    alert("Exported");
    this.OpenModal4("", 0);
    this.setState({exportData:"name expiry_date", fromDate:"", toDate:""})
  };

  Filter = (filter) => {
    this.LoadData(filter);
    this.setState({ filter: filter });
  };

  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "type") {
        this.setState({ switch: event.target.value });
      }
    }
  };

  Returned(key) {
    if (window.confirm("Are you sure you want to mark this returned?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}auth/settings/returnIssueItems`, {
          token: token,
          id: key,
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData(this.state.filter);
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  Delete(key) {
    if (window.confirm("❌ are you sure you want to delete?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}auth/settings/deleteDatabase`, {
          token: token,
          id: key,
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  LoadData = (filter) => {
    // console.log(this.state.data);
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      Axios.get(`${API_BASE}auth/settings/policies/user`);

      this.setState({ loading: true });
      Axios.get(`${API_BASE}auth/settings/listDatabase`, {
        params: { token: token },
      })
        .then((res) => {
          const gd = groupArrayByName(res.data);
          console.log(gd);
          this.setState({ loading: false, data: gd });
        })
        .catch((err) => console.log(err));
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentDidMount() {
    this.LoadData(this.state.filter);
  }

  componentDidUpdate() {
    //initialize datatable
    $(function () {
      $("#stephen").DataTable();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.quick_params !== this.props.data.quick_params) {
      this.LoadData(this.state.filter);
    }
    if (nextProps.data.switch !== this.props.data.switch) {
      this.LoadData(this.state.filter);
    }
  }
  componentWillUnmount() {
    this.props.dispatch(open_right("Open"));
  }
  OpenModal2 = (modal, id) => {
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  LoadFiles = (id) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading2: true });
      Axios.post(`${Home}auth/userFiles`, {
        token: token,
        id: id,
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading2: false, data2: res.data });
        })
        .catch((err) => console.log(err));
    }
  };

  OpenModal3 = (modal, id) => {
    if (id !== 0 || id > 0) {
      this.LoadFiles(id);
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  OpenModal4 = (modal, id) => {
    if (id) {
      //
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };
  OpenModal5 = (modal, id) => {
    this.setState({ current: id });

    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
  };

  handleExportSelection=(e)=>{
    // console.log(e)
   
      this.setState({exportData: this.state.exportData.indexOf(e.target.name) != -1 ? this.state.exportData.replace(e.target.name, ""): this.state.exportData + " " + e.target.name})

   
  }
  render() {
    const { searchQuery, data } = this.state;
    const filteredData = data.filter((group) => {
      // Check if the search query matches any part of the group's name or any data item's properties
      const groupMatches = group.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const dataMatches = group.data.some((item) =>
    getStatus(item.expiry_date)[0] === this.state.filter2 || item.name.toLowerCase().match(searchQuery.toLowerCase())   
        // Object.values(item).some(
        //   (value) =>
        //     value !== null &&
        //     value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        // )
      );
      return dataMatches;
    });

    return (
      <>
        {this.state.modal === "WHOCODED" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <form
                onSubmit={this.handleSubmit}
                className="modal-content card explore-feature border-0 rounded bg-white shadow"
              >
                <div className="modal-header">
                  <h5 className="modal-title">Upload Files</h5>
                  <button
                    onClick={() => this.OpenModal2("", 0)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className=" table-responsive">
                        <App id={this.state.id} url="insure" />
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button onClick={()=>this.OpenModal('WHOCODED',0)} type="button" className="btn btn-success" data-dismiss="modal">{`${this.state.switch === 'WHOCODED'?'Close Edit':'Approve'}`}</button> */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                  <button
                    onClick={() => this.OpenModal2("", 0)}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === "ORIGINALTIMI" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log(this.state.exportData.trim().split(" "))
                  console.log(this.state.fromDate, this.state.toDate);
                  this.Export(this.state.exportData.trim().split(" "));
                }}
                className="modal-content card explore-feature border-0 rounded bg-white shadow"
              >
                <div className="modal-header">
                  <h5 className="modal-title">Export</h5>
                  <button
                    onClick={() => {
                      this.setState({exportData:"name expiry_date", fromDate:"", toDate:""})
                      this.OpenModal4("", 0)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className=" d-flex flex-wrap align-items-center justify-content-around p-2">
                        <div className="">
                          <p>From Date</p>
                          <input
                            required={!!this.state.toDate}
                            onChange={(e) =>
                              this.setState({ fromDate: e.target.value })
                            }
                            type="date"
                            name="from"
                          />
                        </div>

                        <div className="">
                          <p>To Date</p>
                          <input
                            required={!!this.state.fromDate}
                            onChange={(e) =>
                              this.setState({ toDate: e.target.value })
                            }
                            type="date"
                            name="to"
                          />
                        </div>

                        <div>
                          </div>
                      </div>

                      <div className=" d-flex flex-wrap align-items-center justify-content-around p-2">
                          
                          <div>
                            Name: <input onChange={this.handleExportSelection} name="name" checked disabled type="checkbox" />
                            </div>

                            <div>
                            Phone No: <input onChange={this.handleExportSelection} name="telephone" type="checkbox" />
                            </div>

                            <div>
                            Class of Insured: <input onChange={this.handleExportSelection} name="class_insured" type="checkbox" />
                            </div>

                            <div>
                            Policy No: <input onChange={this.handleExportSelection} name="policy_number" type="checkbox" />
                            </div>

                              <div>
                            Expiry Date: <input onChange={this.handleExportSelection} name="expiry_date" 
                            checked
                            disabled
                            type="checkbox" />
                            </div>

                            <div>
                            DOB: <input onChange={this.handleExportSelection} name="dob" type="checkbox" />
                            </div>

                            <div>
                            Email: <input onChange={this.handleExportSelection} name="email" type="checkbox" />
                            </div>

                            <div>
                            Premium Type: <input onChange={this.handleExportSelection} name="premium_type" type="checkbox" />
                            </div>

                            <div>
                            Sum Insured: <input onChange={this.handleExportSelection} name="sum_insured" type="checkbox" />
                            </div>

                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button onClick={()=>this.OpenModal('WHOCODED',0)} type="button" className="btn btn-success" data-dismiss="modal">{`${this.state.switch === 'WHOCODED'?'Close Edit':'Approve'}`}</button> */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    {this.state.exportState}
                  </button>
                  <button
                    onClick={() =>{ 
                      this.setState({exportData:"name expiry_date", fromDate:"", toDate:""})
                      this.OpenModal4("", 0)
                  }}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === "RENEWAL" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                <div className="modal-header">
                  <h5 className="modal-title">Renew Plan</h5>
                  <button
                    onClick={() => this.OpenModal5("", 0)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div>
                        <Renewal current={this.state.current} />
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button onClick={()=>this.OpenModal('WHOCODED',0)} type="button" className="btn btn-success" data-dismiss="modal">{`${this.state.switch === 'WHOCODED'?'Close Edit':'Approve'}`}</button> */}

                  <button
                    onClick={() => this.OpenModal5("", 0)}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === "WHOCODED2" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <form
                onSubmit={this.handleSubmit}
                className="modal-content card explore-feature border-0 rounded bg-white shadow"
              >
                <div className="modal-header">
                  <h5 className="modal-title">Uploaded Files</h5>
                  <button
                    onClick={() => this.OpenModal2("", 0)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.loading2 ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table
                          className="table mb-0 table-striped table-hover table-bordered"
                          id="stephen"
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "7%" }}></th>
                              <th>File Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data2.length > 0 &&
                              this.state.data2.map((dep, i) => (
                                <tr key={i}>
                                  <td>
                                    <img src={img2} className="img-fluid" />
                                  </td>
                                  <td>{dep.name}</td>
                                  <td>
                                    <div>
                                      <FadeIn
                                        duration="1s"
                                        timingFunction="ease-out"
                                      >
                                        <div className=" d-flex">
                                          <a
                                            target="_blank"
                                            href={dep.file}
                                            data-rh="Download Files"
                                            className="btn btn-primary btn-sm m-1 shadow"
                                          >
                                            Download File{" "}
                                          </a>
                                        </div>
                                      </FadeIn>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => this.OpenModal2("", 0)}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          ""
        )}

        <BounceRight duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">List of insured</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      {(this.props.data.userDetails &&
                        this.props.data.userDetails.type === 3) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 1) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 5) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 4) ? (
                        <>
                          <button
                            onClick={() => this.SwitchContent("exit_add", [0])}
                            className="btn btn-primary m-1 shadow"
                          >
                            <PlusCircle color="white" size={35} /> Add New
                          </button>

                          <button
                            onClick={() => this.SwitchContent("renewed", [0])}
                            className="btn btn-primary m-1 shadow"
                          >
                            List Renewed Database
                          </button>

                          <button
                            onClick={() => {
                              this.OpenModal4("ORIGINALTIMI", 0);
                              // this.Export(["name", "telephone", "expiry_date"])
                            }}
                            className="btn btn-primary m-1 shadow"
                          >
                            <File color="white" size={35} /> Export
                          </button>

                          <button
                            onClick={() => this.SwitchContent("import", [0])}
                            className="btn btn-primary m-1 shadow"
                          >
                            <File color="white" size={35} /> Import
                          </button>
                        </>
                      ) : (
                        ""
                      )}

                      {/* <button onClick={()=>this.SwitchContent('exit_add',[0])} className="btn btn-primary m-1 shadow"><PlusCircle color="white" size={35} /> Add New</button> */}

                      {/* <button onClick={()=>this.Filter('all')} className="btn btn-primary shadow m-1"><List color="white" size={35} /> All</button>
<button onClick={()=>this.Filter('returned')} className="btn btn-success shadow m-1"><List color="white" size={35} /> Returned</button>
<button onClick={()=>this.Filter('unreturned')} className="btn btn-warning shadow m-1"><List color="#000" size={35} /> Pending</button> */}
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <div className="p-5">
                <Spinner size={70} />
              </div>
            ) : this.state.data.length < 1 ? (
              <div className="p-5">
                <div className="alert alert-warning text-center">
                  No data yet
                </div>
              </div>
            ) : (
              <div className="p-3 rounded-xl">
                <div>
                  {/* <div className="search-container">
                    <input
                      type="search"
                      className="p-2 search-input"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={this.handleSearchChange}
                    />

                    <div className="filters">
                    <button className={`btn-sm m-1 btn shadow  ${this.state.filter2 == "Active" && "btn-primary"}`} onClick={()=> this.setState({filter2:"Active"})}>
                    Active
                     </button>
                     <button onClick={()=> this.setState({filter2:"Inactive"})}  className={`btn-sm m-1 btn shadow  ${this.state.filter2 == "Inactive" && "btn-primary"}`}>
                    Inactive
                     </button>
                     <button onClick={()=> this.setState({filter2:"Renewal Pending"})}  className={`btn-sm m-1 btn shadow  ${this.state.filter2 == "Renewal Pending" && "btn-primary"}`}>
                    Pending Renewal
                     </button>
                      </div>
                  </div>
                   */}
                    <div >
                      <table 
                        border="1"
                        id="stephen"
                        className="db-table table mb-0 table-striped table-hover table-bordered table-responsive"
                      >

                        <thead>
                          <tr>
                            <th>Insured</th>
                            <th> Policies </th>
                          </tr>
                        </thead>
                        <tbody>

                        {filteredData.map((group, groupIndex) => (
   <tr key={groupIndex}>
   <td
     className="p-3"
     style={{
       width: "100%",
     }}
   >
     {group.name}{" "}
     <span className="badge badge-success">
       {group.data.length}
     </span>

     <button
                   data-rh="Add Multiple"
                   onClick={() =>
                     this.SwitchContent(
                       "exit_add",
                       [0, group.name]
                     )
                   }
                   className="btn btn-primary btn-sm m-1 shadow"
                 >
                   <p>Add Policy</p>
                 </button>
         
   </td>
   <td>
     <table border="1" className="db-table table mb-0 table-striped">
       {/* <button>ADD POLICY TO :::::::::</button> */}
       <thead>
         <tr>
           <th>Insured</th>
           <th>Class of Insured</th>
           <th>Policy Number</th>
           <th>Period of Cover</th>
           <th>Duration</th>
           <th>Expiry Date</th>
           <th>DOB</th>
           <th>Email address</th>
           <th>Telephone Number</th>
           <th>Sum Insured</th>
           <th>Premium Rate</th>
           <th>Interest</th>
           <th>Make</th>
           <th>Premium Type</th>
           <th>Business Indicator</th>
           <th>Status</th>
           <th>Action</th>
         </tr>
       </thead>

       <tbody>
         {group.data
           .slice(
             0,
             this.state.openPolicy == group.name
               ? group.data.length
               : 1
           )
           .map((item, itemIndex) => (
             <Fragment>
              
               <tr key={itemIndex}>
                 <td>{item.name}</td>
                 <td>{item.class_insured}</td>
                 <td>{item.policy_number}</td>
                 <td>{item.period_cover}</td>
                 <td>{item.duration}</td>
                 <td>{item.expiry_date}</td>
                 <td>{item.dob}</td>
                 <td>{item.email}</td>
                 <td>{item.telephone}</td>
                 <td>{item.sum_insured}</td>
                 <td>{item.premium_rate}</td>
                 <td>{item.interest}</td>
                 <td>{item.make}</td>
                 <td>{item.premium_type}</td>
                 <td>{item.business_indicator}</td>
                 <td>
                 <span
       className={`badge badge-${
         getStatus(item.expiry_date)[1]
       } badge-pill`}
     >
       {getStatus(item.expiry_date)[0]}
     </span>
                 </td>
                 <td>
                   <div>
                     <FadeIn
                       duration="1s"
                       timingFunction="ease-out"
                     >
                       <div className=" d-flex">
                         <button
                           data-rh="View uploaded Files"
                           onClick={() =>
                             this.OpenModal3(
                               "WHOCODED2",
                               item.id
                             )
                           }
                           className="btn btn-primary btn-sm m-1 shadow"
                         >
                           <Eye
                             color="white"
                             size={35}
                           />{" "}
                         </button>

                         <button
                           data-rh="Renewal"
                           onClick={() =>
                             this.SwitchContent(
                               "exit_add",
                               [item.id, item.id]
                             )
                           }
                           className="btn btn-primary btn-sm m-1 shadow"
                         >
                           <p>Renew</p>
                         </button>

                         <button
                           data-rh="Upload Files"
                           onClick={() =>
                             this.OpenModal2(
                               "WHOCODED",
                               item.id
                             )
                           }
                           className="btn btn-primary btn-sm m-1 shadow"
                         >
                           <CheckCircle
                             color="white"
                             size={35}
                           />{" "}
                         </button>
                         {(this.props.data.userDetails &&
                           this.props.data.userDetails
                             .type === 3) ||
                         (this.props.data.userDetails &&
                           this.props.data.userDetails
                             .type === 1) ||
                         (this.props.data.userDetails &&
                           this.props.data.userDetails
                             .type === 5) ||
                         (this.props.data.userDetails &&
                           this.props.data.userDetails
                             .type === 4) ? (
                           <button
                             data-rh="Edit "
                             onClick={() =>
                               this.SwitchContent(
                                 "exit_add",
                                 [item.id]
                               )
                             }
                             className="btn btn-primary btn-sm m-1 shadow"
                           >
                             <Edit
                               color="white"
                               size={35}
                             />{" "}
                           </button>
                         ) : (
                           ""
                         )}

                         {(this.props.data.userDetails &&
                           this.props.data.userDetails
                             .type === 3) ||
                         (this.props.data.userDetails &&
                           this.props.data.userDetails
                             .type === 1) ? (
                           <button
                             data-rh="Delete"
                             onClick={() =>
                               this.Delete(item.id)
                             }
                             className="btn btn-danger btn-sm m-1 shadow"
                           >
                             <Trash2
                               color="white"
                               size={35}
                             />{" "}
                           </button>
                         ) : (
                           ""
                         )}
                       </div>
                     </FadeIn>
                   </div>
                 </td>
               </tr>
              
             </Fragment>
           ))}
       </tbody>
     </table>
     {group.data.length > 1 && (
       <p
         onClick={() =>
           this.setState({
             openPolicy:
               this.state.openPolicy == group.name
                 ? ""
                 : group.name,
           })
         }
         className="pt-2 expand-indicator"
       >
         {this.state.openPolicy == group.name
           ? "Collapse"
           : "Expand All"}
       </p>
     )}
   </td>
 </tr>                          
                          ))}
                          </tbody>
                      </table>
                    </div>
                  
                </div>
              </div>
            )}
          </div>
        </BounceRight>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListDatabase);
