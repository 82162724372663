import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelfProfle from './profile/SelfProfle';
import App from './invoicePdf/App';
import EmployeeEditProfile from './profile/EmployeeEditProfile';


class SelfProfileContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list:[1,2,3,4,5,6,7,8,9,10],
            current:"List"
        }
    }

    Switcher=()=>{
        let current = this.props.data.switch;
        if (current === 'user_add') {
            return <h1>Coming Soon</h1>;
        }else if (current === 'user_profile') {
            return <SelfProfle />
        }else if (current === 'user_pdf') {
            return <App />
        }else if (current === 'user_edit_self') {
            return <EmployeeEditProfile />
        }else{
            return <SelfProfle />
        }
    }
    
    render() {
        return (
            <div>
                {this.Switcher()}
            </div>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (SelfProfileContainer);