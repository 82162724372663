import React from 'react';
import { Page, Document, Image, StyleSheet, View,Text } from '@react-pdf/renderer';
import InvoiceItemsTable from './InvoiceItemsTable'
import image from '../../../assets/img/profile.png'


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft:10,
        paddingRight:10,
        lineHeight: 1.5,
        flexDirection: 'column',
        flex:1
    }, 
    logo: {
        width: 94,
        height: 94,
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf:'center'
    },
    logo2: {
        width: 74,
        height: 74,
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf:'center'
    }
  });
  
  const Invoice = ({invoice}) => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{height:780}}>
                        <View style={{flex:1,flexDirection:'row',}}>
                        <View style={{flex:1,borderRightWidth: 1,borderColor: '#0467d2',alignContent:'center'}}>
                          <Image 
                          style={styles.logo}
                          cache={false}
                          source={image}
                            />
                            <View>
                                <Text style={{fontWeight:'extrabold',fontSize:15,textAlign:'center',marginBottom:0,color:'#0467d2',marginTop: 5,}}> 
                                {`${invoice.first_name} ${invoice.middle_name} ${invoice.surname}`}
                                </Text>
                                {/* <Text style={{textAlign:'center',marginTop: -5,}}>Coming Soon</Text> */}
                            </View>

                            <View style={{marginTop: 20,}}>
                                <Text style={{fontWeight:'extrabold',fontSize:15,textAlign:'center',marginBottom:5,color:'#0467d2'}}>Main Details</Text>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>DOB: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.dob}</Text>
                                    </View>
                                </View>

                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>LGA: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.lga}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>State: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.state}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Countery: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.country}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Weight: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.weight}</Text>
                                    </View>
                                </View>
                                

                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Place of birth: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.place_birth}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Gender: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.gender}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Religion: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.religion}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Marital Status: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.marital_status}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Email: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.email}</Text>
                                    </View>
                                </View>
                                
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Phone: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.phone}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Blood Group: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.blood_group}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>R. address: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.r_address}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>P. address: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.p_address}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Language known: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.languages}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Hobbies: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.hobbies}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Height: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.height}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Weight: </Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.weight}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Date</Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.local_government_area}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Date</Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>{invoice.local_government_area}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Date</Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>Coming Soon</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Date</Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>Coming Soon</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row',paddingBottom:5}}>
                                    <View style={{flex:1,paddingLeft:5}}>
                                    <Text style={{textAlign:'left',fontWeight:'extrabold'}}>Date</Text>
                                    </View>
                                    <View style={{flex:2,paddingRight:5}}>
                                        <Text>Coming Soon</Text>
                                    </View>
                                </View>

                                
                            </View>
                            
                        </View>



                        <View style={{flex:1.8,marginLeft: 5,}}>
                            <View style={{paddingLeft:10,marginBottom:15,}}>
                                <Text style={{fontWeight:'extrabold',fontSize:16,textAlign:'left',marginBottom:0,color:'#0467d2'}}>Next of Kin</Text>
                            <View style={{padding:5,borderWidth: 1,borderColor:"#a9ccf5",borderRadius:5}}>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Name: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.first_name:'Not set'} {typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.middle_name:''} {typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.surname:''} </Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Relationship: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.relationship:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Phone Number: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.phone:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Email: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.email:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Postal Address: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.p_address:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Resid. Address: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.nextOfKin)==='object'?invoice.nextOfKin.r_address:'Not set'}</Text>
                                </View>
                            </View> 
                            </View>

                            <View style={{paddingLeft:10,marginBottom:15,}}>
                                <Text style={{fontWeight:'extrabold',fontSize:16,textAlign:'left',marginBottom:0,color:'#0467d2'}}>Dependant Details</Text>
                             <View style={{padding:5,borderWidth: 1,borderColor:"#a9ccf5",borderRadius:5}}>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Name: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.dependant)==='object'?invoice.dependant.first_name:'Not set'} {typeof(invoice.dependant)==='object'?invoice.dependant.middle_name:''} {typeof(invoice.dependant)==='object'?invoice.dependant.surname:''} </Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Relationship: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.dependant)==='object'?invoice.dependant.relationship:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Phone Number: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.dependant)==='object'?invoice.dependant.phone:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Email: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.dependant)==='object'?invoice.dependant.email:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Postal Address: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.dependant)==='object'?invoice.dependant.p_address:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Resid. Address: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.dependant)==='object'?invoice.dependant.r_address:'Not set'}</Text>
                                </View>
                            </View> 
                            </View>

                            <View style={{paddingLeft:10,marginBottom:15,}}>
                                <Text style={{fontWeight:'extrabold',fontSize:16,textAlign:'left',marginBottom:0,color:'#0467d2'}}>Educational Qualifications</Text>
                            <View style={{padding:5,borderWidth: 1,borderColor:"#a9ccf5",borderRadius:5}}>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Institution: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.eduQualification)==='object'?invoice.eduQualification.institution:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Start date: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.eduQualification)==='object'?invoice.eduQualification.startDate:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>End date: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.eduQualification)==='object'?invoice.eduQualification.endDate:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>program of stududy: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.eduQualification)==='object'?invoice.eduQualification.programm:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Qualification obtained: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.eduQualification)==='object'?invoice.eduQualification.qualification:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Class of degree: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.eduQualification)==='object'?invoice.eduQualification.degree:'Not set'}</Text>
                                </View>
                            </View> 
                            </View>

                            <View style={{paddingLeft:10,marginBottom:15,}}>
                                <Text style={{fontWeight:'extrabold',fontSize:16,textAlign:'left',marginBottom:0,color:'#0467d2'}}>Certification</Text>
                            <View style={{padding:5,borderWidth: 1,borderColor:"#a9ccf5",borderRadius:5}}>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Institutional Body: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.certification)==='object'?invoice.certification.institution:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Certification obtained: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.certification)==='object'?invoice.certification.certificate:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Field/Course: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.certification)==='object'?invoice.certification.course:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>lisense/qulification date: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.certification)==='object'?invoice.certification.license:'Not set'}</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:2}}>Expiry date: </Text>
                                    <Text style={{flex:3}}>{typeof(invoice.certification)==='object'?invoice.certification.date:'Not set'}</Text>
                                </View>
                                
                            </View> 
                            </View>

                            <View style={{paddingLeft:10,marginBottom:15,}}>
                                <Text style={{fontWeight:'extrabold',fontSize:16,textAlign:'left',marginBottom:0,color:'#0467d2'}}>NYSC Information</Text>
                            <View style={{padding:5,borderWidth: 1,borderColor:"#a9ccf5",borderRadius:5}}>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:1}}>Satus: </Text>
                                    <Text style={{flex:3}}>Onwe Stephen O </Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:1}}>Year: </Text>
                                    <Text style={{flex:3}}>10th jan. 1960</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <Text style={{fontWeight:'extrabold',flex:1}}>Certificate Number: </Text>
                                    <Text style={{flex:3}}>Y8I9QQ-9QEQ</Text>
                                </View>
                                
                            </View> 
                            </View>
                            
                            
                            
                        </View>
                       
                    </View>
                    </View>
                    



                   {/* <View style={{height:90,marginTop:17}}>
                    <View style={{flex:2,flexDirection:'row',marginTop: 1,}}>
                        <View style={{flex:1,paddingLeft:5,paddingRight:5}}>
                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Name</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>Stephen oWNE</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Student Average</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>Stephen</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>No. of School Open</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>123</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Term Ends</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>12/23/2343</Text>
                                </View>
                            </View>
                        </View>



                        <View style={{flex:1,backgroundColor:'white',paddingLeft:5,paddingRight:5}}>
                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Class</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>Coming</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Class Average</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>Stephen</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>No. of Times Present</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>...</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Next Term Begins</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>...</Text>
                                </View>
                            </View>
                        </View>



                        <View style={{flex:1,paddingLeft:5,paddingRight:5}}>
                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Admission Number</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>...</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Roll id</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>...</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>No. of Times Absent</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>...</Text>
                                </View>
                            </View>

                            <View style={{flex:1,flexDirection:'row'}}>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'left'}}>Position</Text>
                                </View>
                                <View style={{flex:1,}}>
                                    <Text style={{textAlign:'right'}}>...</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View> */}
                    <View>
                        {/* <InvoiceItemsTable invoice={invoice} /> */}
                    </View>
                    
                    
                    {/* <View style={{flex:1,flexDirection:'row',marginLeft:50,marginRight:50,marginTop:50}}>
                        <View style={{flex:1,}}>
                            <Image 
                            style={styles.logo2}
                            cache={false}
                            source={image}
                                />
                                <Text style={{textAlign:'center'}}>Director</Text>
                        </View>

                        <View style={{flex:1}}>
                            <Image 
                            style={styles.logo2}
                            cache={false}
                            source={image}
                                />
                                <Text style={{textAlign:'center'}}>Teacher</Text>
                        </View>
                    </View> */}


                    {/* <InvoiceThankYouMsg /> */}
                </Page>
            </Document>
        );
  
  export default Invoice