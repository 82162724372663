import React, { Component } from 'react';
import { connect } from 'react-redux';
import { open_menu } from '../store/actions/OpenMenu';
import { open_main_menu } from '../store/actions/MainMenu';
import { change_breadcrum } from '../store/actions/Bredcrum';
import SelfProfileContainer from '../components/users/SelfProfileContainer';
import dash from '../assets/svg/pro.svg'
import ComingSoon from '../components/users/components/ComingSoon';
import ProfileContainer from '../components/users/components/profile/ProfileContainer';

class SelfProfilePage extends Component {

    componentDidMount(){
        this.props.dispatch(open_menu('profile'));
        this.props.dispatch(open_main_menu('profile'));
        this.props.dispatch(change_breadcrum('Profile Management'));
    }
    render() {
        console.log(this.props)
        return (
            <div>
                <ProfileContainer img={dash} />
            </div>
        );
    }
}

const mapStoreToProps = (store) =>{
    return{
       data:store
    }
  }

export default connect(mapStoreToProps) (SelfProfilePage);