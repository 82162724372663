function filterKeysInArray(arr, keysToFilter) {
const array = [];

arr.forEach((a)=>{
  a.data.forEach((b)=>{
    array.push(b);
  })
})
  


  return array.map((obj) => {
    const filteredObj = {};
    keysToFilter.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        filteredObj[key] = obj[key];
      }
    });
    return filteredObj;
  });
}

export default filterKeysInArray;
