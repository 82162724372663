import React, { Component } from 'react';
import HashLoader from "react-spinners/HashLoader";

class Spinner extends Component {
    render() {
        return (
            <center>
                <HashLoader
				// css={override}
				size={this.props.size?this.props.size:55}
				color={this.props.color?this.props.color:"#5cb336"}
				loading={true}
				/>
            </center>
        );
    }
}

export default Spinner;