import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#ffffff'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#35357d',
        backgroundColor: '#35357d',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        
    },
    description: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color:'#ffffff',
        textAlign:'left',
        paddingLeft:8,
        marginTop:3
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color:'#ffffff',
        marginTop:3,
       
    },
    rate: {
        width: '18%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color:'#ffffff',
        marginTop:3
    },
    rate2: {
        width: '18%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color:'#ffffff',
        marginTop:3
    },
    amount: {
        width: '19%',
        color:'#ffffff',
        marginTop:3
    },
  });

  const InvoiceTableHeader = ({invoice}) => (
    <View style={styles.container}>
        <Text style={styles.description}>Subjects</Text>
        {typeof(invoice) === 'object' && invoice.marksheetColumn.length > 0 ?
        invoice.marksheetColumn.map((marksheet,i)=>
        <Text key={i} style={styles.qty}>{marksheet}</Text>
        )
    :''}
        
        <Text style={styles.qty}>Total</Text>
        <Text style={styles.rate2}>Highest Mark</Text>
        <Text style={styles.rate2}>Remark</Text>
        <Text style={styles.amount}>Comments</Text>
    </View>
  );
  
  export default InvoiceTableHeader