function groupArrayByName(inputArray) {
    const groupedArray = [];
  
    inputArray.forEach(item => {
      const existingItem = groupedArray.find(group => group.name === item.name);
  
      if (existingItem) {
        existingItem.data.push(item);
      } else {
        groupedArray.push({ name: item.name, data: [item] });
      }
    });
  
    return groupedArray;
  }
  
export default groupArrayByName