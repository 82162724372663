import React, { Component } from "react";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { Link } from "react-router-dom";
import logo from "../assets/img/h.png";
import bar from "../assets/img/bar.png";
import { connect } from "react-redux";
import { open_menu } from "../store/actions/OpenMenu";
import { open_main_menu } from "../store/actions/MainMenu";
import {
  Home,
  User,
  Settings,
  Users,
  LogOut,
  Award,
  Speaker,
  PieChart,
  DollarSign,
  AlertOctagon,
  MessageSquare,
  Share,
  Package,
  BarChart,
  AlignLeft,
  AlignRight,
  Codepen,
  Database,
  HardDrive,
  MessageCircle,
} from "react-feather";
import { BounceRight, FadeIn, BounceLeft } from "animate-components";

class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: "",
      mobile: false,
    };
  }

  OpenMenu = (name, main_name) => {
    this.props.dispatch(open_menu(name));
    this.props.dispatch(open_main_menu(main_name));
  };

  OpenMobileMenu = () => {
    if (window.pageYOffset <= 700) {
      this.setState({ mobile: !this.state.mobile });
    }
  };

  render() {
    // console.log(this.props);
    return (
      <>
        <FadeIn duration="1s" timingFunction="ease-out">
          <aside
            className={`aside aside-fixed left-shadow ${
              this.state.mobile ? "mo-aside" : "de-aside"
            }`}
          >
            <div className="aside-header " style={{ background: "#403e56" }}>
              <Link
                onClick={() => this.OpenMobileMenu()}
                to="#"
                className="aside-logo"
              >
                <img
                  className="img-fluid st-logo-img-dashboard"
                  src={logo}
                  style={{ marginLeft: "-9px" }}
                  alt="Logo"
                />
              </Link>
              <Link
                onClick={() => this.OpenMobileMenu()}
                to="#"
                className="aside-menu-link only-mo"
              >
                <span>
                  <i>{/* <Codepen color="white" size={48} /> */}</i>
                  <span>
                    <img className="img-fluid barr" src={bar} />
                    {/* <MaterialIcon icon="menu" /> */}
                  </span>
                </span>
              </Link>
            </div>

            <div className="aside-body st-scroll ps--active-y bg-b p-0">
              <ul
                className="nav nav-aside st-nav-aside"
                style={{ transition: "0.5s" }}
              >
                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "dashboard" ? "show" : ""
                  } ${this.props.data.menu === "dashboard" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/dashboard" className="nav-link">
                      <Home color="white" size={48} />{" "}
                      <span className="st-link-span">Dashboard</span>
                    </Link>
                  </span>
                </li>
                {(this.props.data.userDetails &&
                  this.props.data.userDetails.type === 3) ||
                (this.props.data.userDetails &&
                  this.props.data.userDetails.type === 1) ||
                (this.props.data.userDetails &&
                  this.props.data.userDetails.type === 0) ||
                (this.props.data.userDetails &&
                  this.props.data.userDetails.type === 5) ? (
                  <li
                    onClick={() => this.OpenMenu("Control", "Control")}
                    className={`nav-item st-link-hover st-nav-pad with-sub ${
                      this.props.data.children === "Control" ? "show" : ""
                    } ${this.props.data.menu === "Control" ? "active" : ""}`}
                  >
                    <span className="st-link">
                      <Link to="#" className="nav-link">
                        <Settings color="white" size={48} />{" "}
                        <span className="st-link-span">Control Panel</span>
                      </Link>
                    </span>
                    <ul className="mt-1" style={{ transition: "0.5s" }}>
                      {(this.props.data.userDetails &&
                        this.props.data.userDetails.type === 3) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 1) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 0) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 5) ? (
                        <li className="st-motion pl-2">
                          <Link
                            className={`d-flex text-white ${
                              this.props.data.submenu === "12"
                                ? "stt-active"
                                : ""
                            }`}
                            to="/settings/products"
                          >
                            <span>&#8592; Products</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {(this.props.data.userDetails &&
                        this.props.data.userDetails.type === 3) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 1) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 0) ||
                      (this.props.data.userDetails &&
                        this.props.data.userDetails.type === 5) ? (
                        <li className="st-motion pl-2">
                          <Link
                            className={`d-flex text-white ${
                              this.props.data.submenu === "18"
                                ? "stt-active"
                                : ""
                            }`}
                            to="/settings/insurer-database"
                          >
                            <span>&#8592; Insurer Database</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {/* {(this.props.data.userDetails &&
                      this.props.data.userDetails.type === 3) ||
                    (this.props.data.userDetails &&
                      this.props.data.userDetails.type === 1) ||
                    (this.props.data.userDetails &&
                      this.props.data.userDetails.type === 0) ||
                    (this.props.data.userDetails &&
                      this.props.data.userDetails.type === 5) ? (
                      <li className="st-motion pl-2">
                        <Link
                          className={`d-flex text-white ${
                            this.props.data.submenu === "19" ? "stt-active" : ""
                          }`}
                          to="/settings/retail-partnership"
                        >
                          <span>&#8592; Retail Partnerships</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )} */}

                      <li className="st-motion pl-2">
                        <Link
                          className={`d-flex text-white ${
                            this.props.data.submenu === "20" ? "stt-active" : ""
                          }`}
                          to="/settings/users"
                        >
                          <span>&#8592; Manage Users</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Items" ? "show" : ""
                  } ${this.props.data.menu === "Items" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/issue_item" className="nav-link">
                      <Package color="white" size={48} />{" "}
                      <span className="st-link-span">Insured Products</span>
                    </Link>
                  </span>
                </li>

                {(this.props.data.userDetails &&
                  this.props.data.userDetails.type === 3) ||
                (this.props.data.userDetails &&
                  this.props.data.userDetails.type === 1) ||
                (this.props.data.userDetails &&
                  this.props.data.userDetails.type === 0) ||
                (this.props.data.userDetails &&
                  this.props.data.userDetails.type === 5) ? (
                  <li
                    className={`nav-item st-link-hover st-nav-pad ${
                      this.props.data.children === "Partner" ? "show" : ""
                    } ${this.props.data.menu === "Partner" ? "active" : ""}`}
                  >
                    <span className="st-link">
                      <Link
                        to="/settings/retail-partnership"
                        className="nav-link"
                      >
                        <Package color="white" size={48} />{" "}
                        <span className="st-link-span">
                          Retail Partnerships
                        </span>
                      </Link>
                    </span>
                  </li>
                ) : (
                  ""
                )}

                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Database" ? "show" : ""
                  } ${this.props.data.menu === "Database" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/database" className="nav-link">
                      <Database color="white" size={48} />{" "}
                      <span className="st-link-span">Database</span>
                    </Link>
                  </span>
                </li>

                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Storage" ? "show" : ""
                  } ${this.props.data.menu === "Storage" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/storage" className="nav-link">
                      <HardDrive color="white" size={48} />{" "}
                      <span className="st-link-span">Storage</span>
                    </Link>
                  </span>
                </li>

                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "profile" ? "show" : ""
                  } ${this.props.data.menu === "profile" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/profile" className="nav-link">
                      <User color="white" size={48} />{" "}
                      <span className="st-link-span">Profile Mangement</span>
                    </Link>
                  </span>
                </li>

                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Universal" ? "show" : ""
                  } ${this.props.data.menu === "Universal" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/universal_insurance" className="nav-link">
                      <Package color="white" size={48} />{" "}
                      <span className="st-link-span">Partnerships</span>
                    </Link>
                  </span>
                </li>

                {/* <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Policy" ? "show" : ""
                  } ${this.props.data.menu === "Policy" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/policy" className="nav-link">
                      <Package color="white" size={48} />{" "}
                      <span className="st-link-span">Policy</span>
                    </Link>
                  </span>
                </li> */}

                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Links" ? "show" : ""
                  } ${this.props.data.menu === "Links" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/certificates" className="nav-link">
                      <Share color="white" size={48} />{" "}
                      <span className="st-link-span">Certificates</span>
                    </Link>
                  </span>
                </li>

                <li
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "SMS" ? "show" : ""
                  } ${this.props.data.menu === "SMS" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <Link to="/sms" className="nav-link">
                      <MessageCircle color="white" size={48} />{" "}
                      <span className="st-link-span">SMS </span>
                    </Link>
                  </span>
                </li>

                {this.props.data.userDetails.hr ? (
                  <li
                    className={`nav-item st-link-hover st-nav-pad ${
                      this.props.data.children === "reports" ? "show" : ""
                    } ${this.props.data.menu === "reports" ? "active" : ""}`}
                  >
                    <span className="st-link">
                      <Link to="/reports" className="nav-link">
                        <BarChart color="white" size={48} />{" "}
                        <span className="st-link-span">Reports</span>
                      </Link>
                    </span>
                  </li>
                ) : (
                  ""
                )}

                {/* <li  className={`nav-item st-link-hover st-nav-pad ${this.props.data.children==='Students'?'show':''} ${this.props.data.menu==='Students'?'active':''}`}>
                       <span className="st-link">
                        <Link to="/COMING" className="nav-link">
                        <Users color="white" size={48} /> <span className="st-link-span">Coming Soon</span></Link>
                        </span>
                        </li> */}

                {/* <li  className={`nav-item st-link-hover st-nav-pad ${this.props.data.children==='COMING'?'show':''} ${this.props.data.menu==='COMING'?'active':''}`}>
                       <span className="st-link">
                        <Link to="/students" className="nav-link">
                        <Users color="white" size={48} /> <span className="st-link-span">Coming Soon</span></Link>
                        </span>
                        </li> */}

                <li
                  onClick={() => this.OpenMenu("", "")}
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Students" ? "show" : ""
                  } ${this.props.data.menu === "Students" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <a href="/logout" className="nav-link">
                      <LogOut color="white" size={48} />{" "}
                      <span className="st-link-span">Log out</span>
                    </a>
                  </span>
                </li>
                {/* <li onClick={()=>this.OpenMenu('','')} className={`nav-item st-link-hover st-nav-pad ${this.props.data.children==='Students'?'show':''} ${this.props.data.menu==='Students'?'active':''}`}>
                            <span className="st-link">
                            <Link to="#" className="nav-link">
                            <MaterialIcon icon="dashboard" />  <span className="st-link-span">Users</span></Link>
                            </span>
                        </li>
                        <li onClick={()=>this.OpenMenu('','')} className={`nav-item st-link-hover st-nav-pad ${this.props.data.children==='Students'?'show':''} ${this.props.data.menu==='Students'?'active':''}`}>
                            <span className="st-link">
                            <Link to="#" className="nav-link">
                            <MaterialIcon icon="dashboard" />  <span className="st-link-span">Users</span></Link>
                            </span>
                        </li> */}
                {/*                     
                    <li className="nav-item st-link-hover with-sub">
                        <Link to="" className="nav-link"><MaterialIcon icon="account_circle" /> <span>User Pages</span></Link>
                        <ul>
                        <li className="st-motion"><Link to="page-profile-view.html">View Profile</Link></li>
                        <li className="st-motion"><Link to="page-connections.html">Connections</Link></li>
                        <li className="st-motion"><Link to="page-groups.html">Groups</Link></li>
                        <li className="st-motion"><Link to="page-events.html">Events</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item st-link-hover with-sub">
                        <Link to="" className="nav-link"><MaterialIcon icon="account_circle" /> <span>Other Pages</span></Link>
                        <ul>
                        <li className="st-motion"><Link to="page-timeline.html">Timeline</Link></li>
                        </ul>
                    </li>
                    <li className="nav-label mg-t-25">User Interface</li>
                    <li className="nav-item st-link-hover" className="st-motion"><Link to="../../components" className="nav-link"><MaterialIcon icon="account_circle" /> <span>Components</span></Link></li>
                    <li className="nav-item st-link-hover" className="st-motion"><Link to="../../collections" className="nav-link"><MaterialIcon icon="account_circle" /> <span>Collections</span></Link></li> */}
              </ul>
            </div>
          </aside>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(LeftNav);
