import React, { Component } from "react";
import { connect } from "react-redux";
import { open_menu } from "../store/actions/OpenMenu";
import { open_main_menu } from "../store/actions/MainMenu";
import { change_breadcrum } from "../store/actions/Bredcrum";
import { BounceRight, FadeIn, BounceLeft } from "animate-components";
import { HardDrive, Package, Users, Database } from "react-feather";
import profile from "../assets/svg/whocoded_p.svg";
import train from "../assets/svg/whocoded_train.svg";
import leav from "../assets/svg/whocoded_leave.svg";
import perform from "../assets/svg/whocoded_perform.svg";
import pay from "../assets/svg/whocoded_pay.svg";
import exit from "../assets/svg/whocoded_exit.svg";
import querry from "../assets/svg/whocoded_querry.svg";
import PieChart from "../components/charts/PieChart";
import BarChart from "../components/charts/BarChart";
import LineChart from "../components/charts/LineChart";
import UsersReports from "../components/reports/UsersReports";
import RecruitmentReport from "../components/reports/RecruitmentReport";
import ReportsController from "../components/reports/ReportsController";
import ucontrol from "../assets/svg/whocoded_control.svg";
import uimg from "../assets/svg/whocoded_team.svg";
import ComingSoon from "../components/users/components/ComingSoon";
import dash from "../assets/svg/dash.svg";
import { Home } from "../global/Home";
import Axios from "axios";
import Spinner from "../global/Spinner";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3, 4, 5, 6],
      data: "",
      modal: "",
      show: "",
      info: "",
      title: "",
      loading: false,
    };
  }

  SampleNavigator = (name) => {
    this.props.history.push(`/${name}`);
  };

  LoadData = () => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}auth/dash`, {
        params: { token: token },
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data });
        })
        .catch((err) => console.log(err));
    }
  };

  componentDidMount() {
    this.LoadData();
    this.props.dispatch(open_menu("dashboard"));
    this.props.dispatch(open_main_menu("dashboard"));
    this.props.dispatch(change_breadcrum("Your Dashboard"));
  }

  OpenModal2 = (modal, id, da) => {
    this.setState({ info: da });
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal }), 600);
    } else {
      this.setState({ modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id });
  };

  render() {
    {
      this.state.data.deleted === 1 && localStorage.clear("userToken");
    }

    console.log(this.state.data);
    return (
      <>
        {this.state.modal === "OPEN" && (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <form
                onSubmit={this.handleSubmit}
                className="modal-content card explore-feature border-0 rounded bg-white shadow"
              >
                {/* <div className="modal-header">
                  <h5 className="modal-title">{this.state.title}</h5>
                  <button
                    onClick={() => this.OpenModal2("", 0, "")}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div> */}
                <div className="modal-body">
                  <div>
                    <h5>{this.state.info.name}</h5>
                    <span className="text-muted font-weight-bold">
                      {this.state.info.created_at
                        ? this.state.info.created_at.slice(0, 10)
                        : ""}
                    </span>
                    <div className="pt-1">
                      <p>{this.state.info.draft}</p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {/* <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button> */}
                  <button
                    onClick={() => this.OpenModal2("", 0, "")}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        <div>
          <FadeIn duration="1s" timingFunction="ease-out">
            {/* <ComingSoon img={dash} /> */}
            <div className="row no-gutters">
              <div className="col-md-3 pr-3 mb-3">
                <div
                  className="card card-custom shadow-none border-0 gutter-b bg-success"
                  style={{ background: "#13da7a" }}
                >
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="d-inline text-white">Insurer</h4>
                    </div>
                  </div>
                  <div
                    id="kt_stats_widget_11_chart"
                    className="d-flex align-self-end"
                    data-color="success"
                    style={{
                      height: "100px",
                      minHeight: "100px",
                      // overflow: "hidden",
                      padding: "10px",
                    }}
                  >
                    <Database color="white" size={48} />
                    {this.state.loading ? (
                      <Spinner size={40} color="#ffffff" />
                    ) : (
                      <h2 className="text-right pt-2 pl-1 text-white">
                        {this.state.data.insure}
                      </h2>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3 pr-3 mb-3">
                <div
                  className="card card-custom shadow-none border-0 gutter-b"
                  style={{ background: "#413e56" }}
                >
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="d-inline text-white">Partnerships</h4>
                    </div>
                  </div>
                  <div
                    id="kt_stats_widget_11_chart"
                    className="d-flex align-self-end"
                    data-color="success"
                    style={{
                      height: "100px",
                      minHeight: "100px",
                      // overflow: "hidden",
                      padding: "10px",
                    }}
                  >
                    <Users color="white" size={48} />
                    {this.state.loading ? (
                      <Spinner size={40} color="#ffffff" />
                    ) : (
                      ""
                    )}{" "}
                    <h2 className="text-right pt-2 pl-1 text-white">
                      {this.state.data.partner}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-md-3 pr-3 mb-3">
                <div
                  className="card card-custom shadow-none border-0 gutter-b bg-danger"
                  style={{ background: "#e01515" }}
                >
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="d-inline text-white">Insured</h4>
                    </div>
                  </div>

                  <div
                    id="kt_stats_widget_11_chart"
                    className="d-flex align-self-end"
                    data-color="success"
                    style={{
                      height: "100px",
                      minHeight: "100px",
                      // overflow: "hidden",
                      padding: "10px",
                    }}
                  >
                    <Package color="white" size={48} />
                    {this.state.loading ? (
                      <Spinner size={40} color="#ffffff" />
                    ) : (
                      ""
                    )}{" "}
                    <h2 className="text-right pt-2 pl-1 text-white">
                      {this.state.data.insured}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-md-3 pr-3 mb-3">
                <div
                  className="card card-custom shadow-none border-0 gutter-b bg-warning"
                  style={{ background: "#f35656" }}
                >
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="d-inline text-white">Storage</h4>
                    </div>
                  </div>
                  <div
                    id="kt_stats_widget_11_chart"
                    className="d-flex align-self-end"
                    data-color="success"
                    style={{
                      height: "100px",
                      minHeight: "100px",
                      // overflow: "hidden",
                      padding: "10px",
                    }}
                  >
                    <HardDrive color="white" size={48} />
                    {this.state.loading ? (
                      <Spinner size={40} color="#ffffff" />
                    ) : (
                      ""
                    )}
                    <h2 className="text-right text-white pt-2 pl-1">
                      {this.state.data.storage}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="colo-b">SMS</h3>
            <div className="row no-gutters">
              <div className="col-md-4 pr-3 mb-3">
                <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                  <div className="card-header  " style={{ background: "#fff" }}>
                    <div className="card-title">
                      <h4 className="colo-b">Birthday</h4>
                    </div>
                  </div>
                  <div
                    className="hideScroll"
                    style={{
                      height: "52.5vh",
                      overflowX: "hidden",
                      scrollbarWidth: "none",
                    }}
                  >
                    {this.state.data.birthday &&
                    this.state.data.birthday.data &&
                    this.state.data.birthday.data.length > 0 ? (
                      <div>
                        {this.state.data.birthday.data.map((da) => (
                          <>
                            <div
                              key={da.id}
                              className="sms"
                              style={{
                                padding: "10px 10px 10px 15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.OpenModal2("OPEN", 0, da);
                              }}
                            >
                              <div>
                                <h5>{da.name}</h5>
                                <span className="text-muted font-weight-bold">
                                  {da.created_at.slice(0, 10)}
                                </span>
                                <div className="pt-1">
                                  <p>{da.draft.slice(0, 150)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom"></div>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="p-4 ">No SMS Yet</div>
                    )}

                    {/* <div className="border-bottom"></div> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4 pr-3 mb-3">
                <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                  <div className="card-header  " style={{ background: "#fff" }}>
                    <div className="card-title">
                      <h4 className="colo-b">Welcome</h4>
                    </div>
                  </div>
                  <div
                    className="hideScroll"
                    style={{
                      height: "52.5vh",
                      overflowX: "hidden",
                      scrollbarWidth: "none",
                    }}
                  >
                    {this.state.data.welcome &&
                    this.state.data.welcome.data &&
                    this.state.data.welcome.data.length > 0 ? (
                      <div>
                        {this.state.data.welcome.data.map((da) => (
                          <>
                            <div
                              key={da.id}
                              className="sms"
                              style={{ padding: "10px 10px 10px 15px" }}
                              onClick={() => this.OpenModal2("OPEN", 0, da)}
                            >
                              <div>
                                <h5>{da.name}</h5>
                                <span className="text-muted font-weight-bold">
                                  {da.created_at.slice(0, 10)}
                                </span>
                                <div className="pt-1">
                                  <p>{da.draft.slice(0, 150)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom"></div>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="p-4 ">No SMS Yet</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4 pr-3 mb-3">
                <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                  <div className="card-header  " style={{ background: "#fff" }}>
                    <div className="card-title">
                      <h4 className="colo-b">Renewal</h4>
                    </div>
                  </div>
                  <div
                    className="hideScroll"
                    style={{
                      height: "52.5vh",
                      overflowX: "hidden",
                      scrollbarWidth: "none",
                    }}
                  >
                    {this.state.data.renewal &&
                    this.state.data.renewal.data &&
                    this.state.data.renewal.data.length > 0 ? (
                      <div>
                        {this.state.data.renewal.data.map((da) => (
                          <>
                            <div
                              key={da.id}
                              className="sms"
                              onClick={() => this.OpenModal2("OPEN", 0, da)}
                              style={{ padding: "10px 10px 10px 15px" }}
                            >
                              <div>
                                <h5>{da.name}</h5>
                                <span className="text-muted font-weight-bold">
                                  {da.created_at.slice(0, 10)}
                                </span>
                                <div className="pt-1">
                                  <p>{da.draft.slice(0, 150)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom"></div>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="p-4 ">No SMS Yet</div>
                    )}

                    {/* <div className="border-bottom"></div> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4 py-3 pr-3">
                <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                  <div className="card-header  " style={{ background: "#fff" }}>
                    <div className="card-title">
                      <h4 className="colo-b">Claim</h4>
                    </div>
                  </div>
                  <div
                    className="hideScroll"
                    style={{
                      height: "52.5vh",
                      overflowX: "hidden",
                      scrollbarWidth: "none",
                    }}
                  >
                    {this.state.data.claim &&
                    this.state.data.claim.data &&
                    this.state.data.claim.data.length > 0 ? (
                      <div>
                        {this.state.data.claim.data.map((da) => (
                          <>
                            <div
                              key={da.id}
                              className="sms"
                              onClick={() => this.OpenModal2("OPEN", 0, da)}
                              style={{ padding: "10px 10px 10px 15px" }}
                            >
                              <div>
                                <h5>{da.name}</h5>
                                <span className="text-muted font-weight-bold">
                                  {da.created_at.slice(0, 10)}
                                </span>
                                <div className="pt-1">
                                  <p>{da.draft.slice(0, 150)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom"></div>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="p-4 ">No SMS Yet</div>
                    )}

                    {/* <div className="border-bottom"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(Dashboard);
